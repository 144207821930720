import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { updatebrandAction, getbrandidAction } from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import avt from '../assets/avata_profile.jpg'
import { Link } from 'react-router-dom';

const Editbrand = () => {
  const [getbrandlist, setBrandList] = useState({});
  const [form, setForm] = useState({ name: "",brand_status: ""  });
  const [validatioError, setvalidatioError] = useState({});
 
  useEffect(() => {
    getBrandid();
  }, []);

  const getBrandid = async () => {
    const id = window.location.href.split("/").pop();
    let res = await getbrandidAction({ id: id  });
    if (res.success) {
      setBrandList(res.data);
      let data = res.data[0];
      setForm((old) => {
        return { ...old, id: id, brand_name: data.brand_name,brand_status:data.brand_status };
      });
    }
  };
  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };
function validate() {
    let nameError = "";   
    let statusError = "";

    if (form.brand_name === "") {
      nameError = "Brand Name is required.";
    }
   
    if (form.brand_status === "") {
      statusError = "Brand status is required.";
    }

    if (nameError || statusError) {
      setvalidatioError({
        nameError, statusError,
      });
      return false;
    } else {
      return true;
    }
  }
 
  const updatebrand = async (e) => {
    e.preventDefault(); 
    const isValid = validate();
    if (!isValid) {
    } else {	
		let res = await updatebrandAction(form);
		if (res.success) {
		  toast.success(res.msg);
		  setTimeout(() => {
			window.location.href = `${config.baseUrl}brands`;
		  }, 1200);
		} else {
		  toast.error(res.msg);
		}
	}
  };

  return (
    <>
      <div className="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-5 pb-2">Edit Brand</h3>
                </div>
              </div>
              <hr />
            </div>
            {/* Content Header (Page header) */}
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Edit Brand</h4>
                       <a href={`${config.baseUrl}brands`} className="btn btn-sm btn-primary add_btn">Back</a> 
                    </div>
                    <div className="row mt-20 mb-50">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">
                              Brand Name <span className="req-star">*</span>
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                name="brand_name"
                                value={form.brand_name}
                                onChange={inputHandler}
                                placeholder=""
                              />
                            </div>
                              <span className="validationErr">
                              {validatioError.nameError}
                            </span>
                          </div>
                           <div className="form-group row mb-1">
							<label className="col-form-label col-md-12">
								Status <span className="req-star">*</span>
							</label>
							<div className="col-md-12">
								<select name='brand_status' className="form-control" id='brand_status' onChange={inputHandler} value={form?.brand_status}>
									<option value="">Status</option>
									<option value="1">Active</option>
									<option value="0">In-Active</option>
								</select>
							</div>
							<span className="validationErr">
								{validatioError.statusError}
							</span>
						</div>	
                         

                          <br />
                          <br />
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={updatebrand}
                            >
                              Save Change
                            </button>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Editbrand;
