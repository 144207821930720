import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { getAllbrandslistAction,getRoleslistAction,updateUserDetailsAction,getUserDetailsAction,changeUserPasswordAction } from "../Action/action";
import { useParams } from 'react-router-dom';
const loginData = (!Cookies.get('loginSuccessltsAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessltsAdmin'));


const Edituser = () => {
	const { id } = useParams();
	const [Brands, setBrands] 					= useState([]);
	const [Roles, setRoles] 					= useState([]);  
    const [spinloader, setspinloader] 			= useState(0);
    const [pwdspinloader, setpwdspinloader] 	= useState(0);
    const [spindetailloader, setspindetailloader] = useState(0);   
	const [validatioError, setvalidatioError] 	= useState({});
	const [selectedBrands, setSelectedBrands] 	= useState([]);
	const [userdata, setuserdata] 				= useState({});
	const [userpassworddata, setuserpassworddata] = useState({
	password:'',
	confirm_password: ''
	});
   
  
   useEffect(() => {
	    if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
        getBrandsAPI()       
        getRolesAPI() 
        getUserDetailsAPI();        
    }, []);
     
     const getUserDetailsAPI = async () => {
		 setspindetailloader(1);
        let res = await getUserDetailsAction({ 'id': id });
        if (res.success) {
            setuserdata(res.data)
            setSelectedBrands(res.userbrands);  
            
            setspindetailloader(0);
        }
    }     
  const getBrandsAPI = async () => {
        let res = await getAllbrandslistAction();
        if (res.success) {
            setBrands(res.data)
        }
    }
    
  const getRolesAPI = async () => {
        let res = await getRoleslistAction();
        if (res.success) {
            setRoles(res.data)
        }
    }

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setuserdata((old) => {
      return { ...old, [name]: value };
    });
  };
  
  const inputPasswordHandler = async (e) => {
    const { name, value } = e.target;
    setuserpassworddata((old) => {
      return { ...old, [name]: value };
    });
  };
  
  const inputRoleHandler = e => {
        const { name, value, id } = e.target
        setuserdata({ ...userdata, [name]: value })
        if (value !== '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }      
    }
  
  
  const handleBrandChange = (value) => {
    if (selectedBrands.includes(value)) {
      // Value exists in the array, remove it
      setSelectedBrands(selectedBrands.filter((brandId) => brandId !== value));
    } else {
      // Value does not exist in the array, add it
      setSelectedBrands([...selectedBrands, value]);
    }
  };
	
  function validate() {	 
	userdata.brands = selectedBrands;	
    let firstnameError = "";
    let lastnameError = "";
    let emailError = "";  
    let roleError = "";
    let brandsError = "";
    let statusError = "";
       
    if (userdata.first_name === "") {
      firstnameError = "First name is required.";
    }  
    if (userdata.last_name === "") {
      lastnameError = "Last name is required.";
    }  
    if (userdata.email === "") {
      emailError = "Email is required.";
    }     
    if (userdata.role_id === "0") {
      roleError = "Role is required.";
    } 
   
    if (selectedBrands.length === 0) {
      brandsError = "Brands are required.";
    }
    if (userdata.email !== '') {
		  const isValidemail = validateEmail(userdata.email);
		  if (!isValidemail) { 
			  emailError = "Please enter valid email address.";
		 }
	 }  
   
    if (firstnameError || lastnameError || emailError || roleError || statusError || brandsError) {		
		
      setvalidatioError({
        firstnameError,lastnameError,emailError,roleError,statusError,brandsError
      });
      return false;
    } else {
      return true;
    }
  }
  function validatepassword() {	 
	let passwordError = "";
    let confirm_passwordError = "";
    if (userpassworddata.password === "") {
      passwordError = "Password is required.";
    }  
    if (userpassworddata.confirm_password === "") {
      confirm_passwordError = "Confirm password is required.";
    }  
    if (userpassworddata.password !== '') {		
		if (userpassworddata.password.length < '8') {
			passwordError = "Password must be 8 character long."			
		}		
		if (userpassworddata.password !== userpassworddata.confirm_password) {
			confirm_passwordError = "Confirm password do not match."			
		}
	}
   
    if (passwordError || confirm_passwordError) {		
		
      setvalidatioError({
        passwordError , confirm_passwordError
      });
      return false;
    } else {
      return true;
    }
  }
  function validateEmail(email) {
		// Regular expression for email validation
		const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
		return regex.test(email);
	}  
  const updateUserDetails = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {		
        }
        else {
            setspinloader(1);
           
			console.log(userdata);		                             
			let res = await updateUserDetailsAction(userdata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}users`;
				}, 2000);
			}			 
			else {
				toast.error(res.msg);
			}
		} 
	}
 
  const changeUserPassword = async (e) => {
        e.preventDefault()
        const isValid = validatepassword();
        if (!isValid) {		
        }
        else {
            setpwdspinloader(1);
           
			console.log(userdata);		                             
			let res = await changeUserPasswordAction({ user_id : userdata.id,password:userpassworddata.password,confirmPassword:userpassworddata.confirm_password});
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}Edituser/${userdata.id}`;
				}, 2000);
			}			 
			else {
				toast.error(res.msg);
			}
		} 
	}
  
  return (
        <>
            <div className="wrapper">
                <Header />
                <Toaster />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Edit User</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        {/* Content Header (Page header) */}

                        {/* Main content */}
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-header with-border">
                                            <h4 className="box-title">Edit User</h4>
                                             <a href={`${config.baseUrl}users`} className="btn btn-sm btn-primary add_btn">Back</a> 
                                        </div>
                                        <div className="row mt-20 mb-50 ml-15 mr-15">
                                            <form onSubmit={updateUserDetails}>
                                             <div className="col-md-12">
                                                <div className="row">                                               
                                                    <div className="col-md-6">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                First Name <span className="req-star">*</span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="first_name"
                                                                    id='firstnameError'
                                                                    onChange={inputHandler}
                                                                    placeholder="Enter First Name"
                                                                    value={userdata?.first_name}
                                                                />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.firstnameError}
                                                            </span>
                                                        </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Last Name <span className="req-star">*</span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="last_name"
                                                                    id='lastnameError'
                                                                    onChange={inputHandler}
                                                                    placeholder="Enter Last Name"
                                                                    value={userdata?.last_name}
                                                                />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.lastnameError}
                                                            </span>
                                                        </div>
                                                        </div>
                                                         <div className="col-md-6">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Email <span className="req-star">*</span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input
                                                                    className="form-control"
                                                                    type="email"
                                                                    name="email"
                                                                    id='emailError'
                                                                    onChange={inputHandler}
                                                                    placeholder="Enter Email Address"
                                                                    value={userdata?.email}
                                                                    readonly
                                                                    disabled
                                                                />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.emailError}
                                                            </span>
                                                        </div>
                                                        </div>
                                                         <div className="col-md-6">
                                                         <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Role <span className="req-star">*</span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select name='role_id' className="form-control" id='roleError' onChange={inputRoleHandler} value={userdata?.role_id}>
                                                                    <option value="0">Select Role</option>
                                                                    {Roles.map(Role => (
                                                                        <option value={Role.id}>{Role.title}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.roleError}
                                                            </span>
                                                        </div>
                                                        </div>                                               
                                                         <div className="col-md-12">
														  <div className="form-group row mb-1">
															<label className="col-form-label col-md-12">Brands <span className="req-star">*</span></label>
															<div className="col-md-12">
															{spindetailloader === '1' ? (
																  <i className="fa fa-spinner fa-spin validat"></i>
																) : (
																 Brands.map(brand => (
																	  <span key={brand.brand_id}>
																		<input
																		  type="checkbox"
																		  id={`brand_${brand.brand_id}`}
																		  value={brand.brand_id}
																		  checked={selectedBrands.includes(brand.brand_id)}
																		  onChange={() => handleBrandChange(brand.brand_id)}
																		/> {brand.brand_name}&nbsp;&nbsp;
																	  </span>
																	))

																)}

															</div>
														  </div>  
														  <div className="validationErr">
															{validatioError.brandsError}
														  </div>
														</div>											 
                                                        
														
                                                         <div className="col-md-12">

                                                        <br />
                                                        <div className="text-center pull-left">
                                                            {spinloader == '0' ?
                                                                <button className='btn btn-primary' >Submit</button>
                                                                :
                                                                <button disabled className='btn btn-primary' >Updating User <i className="fa fa-spinner fa-spin validat"></i></button>
                                                            }
                                                        </div>
                                                         </div>
                                                    </div>                                                   
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                 <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-header with-border">
                                            <h4 className="box-title">Change Password</h4>
                                           </div>
                                        <div className="row mt-20 mb-50 ml-15 mr-15">
                                            <form onSubmit={changeUserPassword}>
                                             <div className="col-md-12">
                                                <div className="row">                                               
                                                    <div className="col-md-6">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                              Password <span className="req-star">*</span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input
                                                                    className="form-control"
                                                                    type="password"
                                                                    name="password"
                                                                    id='passwordError'
                                                                    onChange={inputPasswordHandler}
                                                                    placeholder="Enter New Password"
                                                                   />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.passwordError}
                                                            </span>
                                                        </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                               Confirm Password <span className="req-star">*</span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input
                                                                    className="form-control"
                                                                    type="password"
                                                                    name="confirm_password"
                                                                    id='confirm_passwordError'
                                                                    onChange={inputPasswordHandler}
                                                                    placeholder="Enter Confirm Password"
                                                                    />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.confirm_passwordError}
                                                            </span>
                                                        </div>
                                                        </div> 
                                                         <div className="col-md-12">
                                                        <br />
                                                        
                                                        <div className="text-center pull-left">
                                                            {pwdspinloader == '0' ?
                                                                <button className='btn btn-primary' >Change Password</button>
                                                                :
                                                                <button disabled className='btn btn-primary' >Changing Password <i className="fa fa-spinner fa-spin validat"></i></button>
                                                            }
                                                        </div>
                                                         </div>
                                                    </div>                                                   
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                            </div>
                        </section>
                        {/* /.content */}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};
export default Edituser;
