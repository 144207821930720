import "./App.css";
import React, { components } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import config from './config/config.js'
import config from "./coreFIles/config";
import Login from "./component/login";
import Dashboard from "./component/dashboard";
import Profile from "./component/profile";
import Users from "./component/users";
import Adduser from "./component/adduser";
import Edituser from "./component/edituser";
import Changepassword from "./component/changepassword";
import Brands from "./component/brands";
import Addbrand from "./component/addbrand";
import Editbrand from "./component/editbrand";
import Stages from "./component/stages";
import Addstage from "./component/addstage";
import Editstage from "./component/editstage";
import Leads from "./component/leads";
import Addleads from "./component/addleads";
import Editleads from "./component/editleads";
import Priority from "./component/priority";
import Editpriority from "./component/editpriority";
import Medium from "./component/medium";
import Editmedium from "./component/editmedium";




function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Login />} />
          <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />
          <Route path={`${config.baseUrl}users`} element={<Users />} />
		   <Route
            path={`${config.baseUrl}Adduser`}
            element={<Adduser />}
          />
          <Route
            path={`${config.baseUrl}Edituser/:id`}
            element={<Edituser />}
          />
          
          <Route path={`${config.baseUrl}leads`} element={<Leads />} />
		  <Route
            path={`${config.baseUrl}Addleads`}
            element={<Addleads />}
          />
		<Route
            path={`${config.baseUrl}Editleads/:id`}
            element={<Editleads />}
          />
       <Route path={`${config.baseUrl}priority`} element={<Priority />} />          
          <Route
            path={`${config.baseUrl}editpriority/:id`}
            element={<Editpriority />}
          />
       <Route path={`${config.baseUrl}medium`} element={<Medium />} />          
          <Route
            path={`${config.baseUrl}editmedium/:id`}
            element={<Editmedium />}
          />
          
        
          
           <Route path={`${config.baseUrl}brands`} element={<Brands />} />
           <Route
            path={`${config.baseUrl}Addbrand`}
            element={<Addbrand />}
          />
          <Route
            path={`${config.baseUrl}Editbrand/:id`}
            element={<Editbrand />}
          />
            <Route path={`${config.baseUrl}stages`} element={<Stages />} />
           <Route
            path={`${config.baseUrl}Addstage`}
            element={<Addstage />}
          />
          <Route
            path={`${config.baseUrl}Editstage/:id`}
            element={<Editstage />}
          />
         

          <Route
            path={`${config.baseUrl}profile`}
            element={<Profile />}
          />

          <Route
            path={`${config.baseUrl}changepassword`}
            element={<Changepassword />}
          /> 

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
