import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import ReactDatatable from '@ashvin27/react-datatable';
import Cookies from 'js-cookie'
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { getAllbrandslistAction,updateLeadAction,getAllBrandsUsersAction,getAllstagelistAction,getLeadDetailsAction,getBrandUsersAction,getUsersListAction,getLeadHistoryAction,addLeadhistoryAction,getAllprioritylistAction, getAllmediumlistAction } from "../Action/action";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link, useParams } from 'react-router-dom';
const loginData = (!Cookies.get('loginSuccessltsAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessltsAdmin'));


const Editleads = () => {
	const { id } = useParams();
	const [Brands, setBrands] = useState([]);
	const [Stages, setStages] = useState([]);
	const [AllBrandsUsers, setAllBrandsUsers] = useState([]);
	const [AllUsers, setAllUsers] = useState([]);
	const [LeadHistory, setLeadHistorys] = useState([]);
	const [filteredUsers, setfilteredUsers] = useState([]);
	const [spinloader, setspinloader] = useState(0);
	const [spinhistoryloader, setspinhistoryloader] = useState(0);
	const [spindetailloader, setspindetailloader] = useState(0);
    const [currentDate, setcurrentDate] = useState(new Date());  
	const [validatioError, setvalidatioError] = useState({});
	const [validationError, setvalidationError] = useState({});
	const [selectedBrands, setSelectedBrands] = useState([]);
	const [leaddata, setleaddata] = useState({});
	const [mediumlist, setmediumList] 				= useState([]); 
    const [prioritylist, setpriorityList] 			= useState([]);	
	const [leadhistoydata, setleadhistorydata] = useState({
        message: '',
        follow_up_datetime: null,
        created_by: loginData?.id,
        current_lead_stage: '',
        leadstage: '',
        lead_id: id,             
    });
   
  
   useEffect(() => {	   
	    if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
        getpriorityAPI();
        getmediumAPI();
        getBrandsAPI()    
        getStagesAPI()  
        getallUsersAPI()
        getAllBrandsUsersAPI();      
        getLeadDetailsAPI();
        getLeadHistoryAPI();
        
       
      
    }, [])
  
    const getLeadDetailsAPI = async () => {
		setspindetailloader(1);
        let res = await getLeadDetailsAction({ 'id': id });
        if (res.success) {
            setleaddata(res.data)
            getBrandUsersAPI(res.data.brand_id);         
              
        }
    } 
    const getLeadHistoryAPI = async () => {
		let res = await getLeadHistoryAction({ 'id': id });
        if (res.success) {
            setLeadHistorys(res.data)
        }
    }
    
    const getpriorityAPI = async () => {
		let res = await getAllprioritylistAction();
		if (res.success) {
		  setpriorityList(res.data);
		}
	  };
	const getmediumAPI = async () => {
		let res = await getAllmediumlistAction();
		if (res.success) {		 
		  setmediumList(res.data);
		}
	  }; 
        
  const getBrandsAPI = async () => {
        let res = await getAllbrandslistAction();
        if (res.success) {
            setBrands(res.data)
        }
    }  

  const getStagesAPI = async () => {
        let res = await getAllstagelistAction();
        if (res.success) {
            setStages(res.data)
        }
    }  

  const getallUsersAPI = async () => {
        let res = await getUsersListAction();
        if (res.success) {
            setAllUsers(res.data)
        }
    }  

  const getBrandUsersAPI = async (brand_id) => {
	   let res = await getBrandUsersAction({brand_id:brand_id});
        if (res.success) {			
			 setfilteredUsers(res.data);	
			 setspindetailloader(0);		 
		 }        
    }   
  const getAllBrandsUsersAPI = async () => {
	   let res = await getAllBrandsUsersAction();
        if (res.success) {
			 setAllBrandsUsers(res.data)
			 const users = res.data;
			  console.log(users); 
			 const result = users.filter(user => user.brand_id == leaddata.brand_id);   
			 console.log(result);  
			 setfilteredUsers(result);	
			 setspindetailloader(0);		 
		 }        
    }   

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setleaddata((old) => {
      return { ...old, [name]: value };
    });
  };
  
  const inputhistoryHandler = async (e) => {
    const { name, value } = e.target;
    setleadhistorydata((old) => {
      return { ...old, [name]: value };
    });
  };
  
  const inputBrandHandler = e => {
        const { name, value, id } = e.target        
        setleaddata({ ...leaddata, [name]: value })
        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }    
      const result = AllBrandsUsers.filter(user => user.brand_id == value);     
      setfilteredUsers(result);  
    }
  
  function validate() {	 
	let first_nameError 	= "";
	let last_nameError 		= "";   
    let emailError 			= "";
    
    let stageError 			= "";
    let brand_idError 		= "";
    let user_idError 		= "";
    let priority_idError 	= "";
    let medium_idError 		= "";
       
    if (leaddata.first_name === "") {
      first_nameError = "First name is required.";
    }  
    if (leaddata.last_name === "") {
      last_nameError = "Last name is required.";
    } 
    
    if (leaddata.email === "") {
      emailError = "Email is required.";
    }     
    
    if (leaddata.stage === "") {
      stageError = "Stage is required.";
    } 
    if (leaddata.brand_id === "") {
      brand_idError = "Brand is required.";
    } 
    if (leaddata.user_id === "") {
      user_idError = "User is required.";
    } 
    if (leaddata.priority_id === "") {
      priority_idError = "Priority type is required.";
    }
    if (leaddata.medium_id === "") {
      medium_idError = "Medium is required.";
    }  
    if (first_nameError || last_nameError || emailError || stageError || brand_idError || user_idError || priority_idError || medium_idError) {
      setvalidatioError({
        first_nameError, last_nameError, emailError,stageError,brand_idError,user_idError,priority_idError,medium_idError
      });
      return false;
    } else {
      return true;
    }
  }
  
  function historyvalidate() {	 
	let messageError 	= "";
	let datetimeError 		= "";       
	let leadstageError 		= "";       
    if (leadhistoydata.message === "") {
      messageError = "Message is required.";
    }  
    if (leadhistoydata.leadstage === "") {
      leadstageError = "Lead stage is required.";
    }  
    if (leadhistoydata.follow_up_datetime === "" || leadhistoydata.follow_up_datetime === null) {
      datetimeError = "Follow up date and time is required.";
    }
    else
    {
		const today = new Date();	
		const selectedDateTime = new Date(leadhistoydata.follow_up_datetime);	   
		if (parseInt(selectedDateTime.getTime()) < parseInt(today.getTime())) {	
			 datetimeError = "Selected date cannot be in the past.";
		}
		
	} 
    
   
    if (messageError || datetimeError || leadstageError) {
      setvalidationError({
        messageError, datetimeError,leadstageError
      });
      return false;
    } else {
      return true;
    }
  }
  
   const handleChangefollowupDate  = (moment) => {
	   const today = new Date();	
	   const selectedDateTime = new Date(moment._d);	   
		if (parseInt(selectedDateTime.getTime()) < parseInt(today.getTime())) {	 
			
			 setleadhistorydata({ ...leadhistoydata, ['follow_up_datetime']: moment._d  });  
			setvalidationError({
			  ...validationError,
			  datetimeError: 'Selected date cannot be in the past',
			});
		  } 
		else
		{
			
		 setleadhistorydata({ ...leadhistoydata, ['follow_up_datetime']: moment._d  });
         setvalidationError({ ...validationError, ['datetimeError']: '' });	
		}  
      
    }
    
  const updateLead = async (e) => {
        e.preventDefault()
        const isValid = validate();       
        if (!isValid) {		
        }
        else {
            setspinloader(1);
           
			let res = await updateLeadAction(leaddata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}leads`;
				}, 2000);
			}			 
			else {
				toast.error(res.msg);
			}
		} 
	}

   function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
  
  const addLeadhistory = async (e) => {
        e.preventDefault()
        const isValid = historyvalidate();       
        if (!isValid) {		
        }
        else {
        setspinhistoryloader(1)
			const jsDate = new Date(leadhistoydata.follow_up_datetime);
			const mysqlDatetime = moment(jsDate).format('YYYY-MM-DD HH:mm:ss');
			leadhistoydata.current_lead_stage= leaddata.stage   
			leadhistoydata.follow_up_datetime= mysqlDatetime  
			console.log(leadhistoydata)        
			let res = await addLeadhistoryAction(leadhistoydata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}editLeads/${id}`;
				}, 2000);
			}			 
			else {
				toast.error(res.msg);
			}
		} 
	}
  
  
    const columns = [
        {
            key: "Sno.",
            text: "#",
            cell: (row, index) => index + 1,
            width: '10%', 
        },        
        {
            key: "message",
            text: "Message",  
            width: '50%', 
            cell: (item) => {
        return (
          <>
            <div className="message">{item.message}</div>
          </>
        );
      },          
        },
        {
            key: "follow_up_datetime",
            text: "Follow up on",
             width: '10%',   
             
        },        
        {
            key: "created_by_name",
            text: "Created by",  
              width: '10%',       
        },      
        {
            key: "lead_stage_title",
            text: "Previous Stage",
              width: '10%',   
        },       
        {
            key: "created_on",
            text: "Created On",
            width: '10%',   
            cell: (item) => {
                return (
                    `${moment(item.created_on).format('DD/MM/YYYY')}`
                );
            }
        },       
    ];

    const configForTable = {
        page_size: 10,
        length_menu: [10, 20, 50, 100],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false

        }
    }
  
  
  return (
        <>
            <div className="wrapper">
                <Header />
                <Toaster />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Edit Lead</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        {/* Content Header (Page header) */}

                        {/* Main content */}
<section className="content">
<div className="row">
<div className="col-lg-12 col-12">
<div className="box">
<div className="box-header with-border">
<h4 className="box-title">Edit Lead</h4>
 <a href={`${config.baseUrl}leads`} className="btn btn-sm btn-primary add_btn">Back</a> 
</div>
<div className="row mt-20 mb-50 ml-15 mr-15">
  <div className="col-md-12">
		<Tabs>
			<TabList>
				<Tab>Lead Details</Tab>
				<Tab>Lead Communication</Tab>       
			</TabList>
	<TabPanel>		
		<form onSubmit={updateLead}>
			<div className="col-md-12">
				<div className="row">                                               
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">First Name <span className="req-star">*</span></label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="first_name" id='first_nameError' onChange={inputHandler} placeholder="Enter first name" value={leaddata?.first_name}/>
							</div>
							<span className="validationErr">{validatioError.first_nameError}</span>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Last Name <span className="req-star">*</span></label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="last_name" id='last_nameError' onChange={inputHandler} placeholder="Enter last name" value={leaddata?.last_name}/>
							</div>
							<span className="validationErr">{validatioError.last_nameError}</span>
						</div>
					</div>					
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Email <span className="req-star">*</span></label>
							<div className="col-md-12">
								<input className="form-control" type="email" name="email" id='emailError' onChange={inputHandler} placeholder="Enter email address" value={leaddata?.email}/>
							</div>
							<span className="validationErr">{validatioError.emailError}</span>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Personal Email</label>
							<div className="col-md-12">
								<input className="form-control" type="email" name="personal_email" id='personal_emailError' onChange={inputHandler} placeholder="Enter personal email address" value={leaddata?.personal_email}/>
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Phone </label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="phone" id='phoneError' onChange={inputHandler} placeholder="Enter phone" value={leaddata?.phone}/>
							</div>
							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Corporate phone</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="corporate_phone" id='corporate_phoneError' onChange={inputHandler} placeholder="Enter corporate phone" value={leaddata?.corporate_phone}/>
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Linkedin URL</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="linkedin_url" id='linkedin_urlError' onChange={inputHandler} placeholder="Enter linkedin url" value={leaddata?.linkedin_url}/>
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Designation</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="designation" id='designationError' onChange={inputHandler} placeholder="Enter designation" value={leaddata?.designation}/>
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Organisation</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="organisation" id='organisationError' onChange={inputHandler} placeholder="Enter organisation" value={leaddata?.organisation}/>
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Geography</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="geography" id='geographyError' onChange={inputHandler} placeholder="Enter geography" value={leaddata?.geography}/>
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Sales navigator url</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="sales_navigator_url" id='sales_navigator_urlError' onChange={inputHandler} placeholder="Enter sales navigator url" value={leaddata?.sales_navigator_url}/>
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Website</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="website" id='websiteError' onChange={inputHandler} placeholder="Enter website" value={leaddata?.website}/>
							</div>							
						</div>
					</div>				
				
					<div className={loginData?.role != 1 ? 'col-md-6 dpnone' : 'col-md-6'} >
					  <div className="form-group row mb-1">
						<label className="col-form-label col-md-12">
							Brand <span className="req-star">*</span>
						</label>
						<div className="col-md-12">
							<select name='brand_id' className="form-control" id='brand_idError' onChange={inputBrandHandler} value={leaddata?.brand_id}>
								<option value="">Select Brand</option>
								{Brands.map(Brand => (
									<option value={Brand.brand_id}>{Brand.brand_name}</option>
								))}
							</select>
						</div>
						<span className="validationErr">
							{validatioError.brand_idError}
						</span>
					</div>
					</div>
					
					<div className="col-md-6">
			  <div className="form-group row mb-1">
				<label className="col-form-label col-md-12">
					Priority Type <span className="req-star">*</span>
				</label>
				<div className="col-md-12">
					<select name='priority_id' className="form-control" id='priority_idError' onChange={inputHandler} value={leaddata?.priority_id}>
						<option value="">Select priority type</option>
						{prioritylist.map(priority => (
							<option value={priority.id}>{priority.title}</option>
						))}
					</select>
				</div>
				<span className="validationErr">
					{validatioError.priority_idError}
				</span>
			</div>
			</div>	
			
			<div className="col-md-6">
			  <div className="form-group row mb-1">
				<label className="col-form-label col-md-12">
					Medium <span className="req-star">*</span>
				</label>
				<div className="col-md-12">
					<select name='medium_id' className="form-control" id='medium_idError' onChange={inputHandler} value={leaddata?.medium_id}>
						<option value="">Select medium</option>
						{mediumlist.map(medium => (
							<option value={medium.id}>{medium.title}</option>
						))}
					</select>
				</div>
				<span className="validationErr">
					{validatioError.medium_idError}
				</span>
			</div>
			</div>		
							
					<div className={loginData?.role != 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
					  <div className="form-group row mb-1">
						<label className="col-form-label col-md-12">
							Owned User <span className="req-star">*</span>
						</label>
						<div className="col-md-12">
						{spindetailloader == '1' ?
							<i className="fa fa-spinner fa-spin validat"></i>
							:
							<select name='user_id' className="form-control" id='user_idError' onChange={inputHandler} value={leaddata?.user_id}>
								<option value="">Select User</option>
								{filteredUsers.map(Usr => (
									<option value={Usr.id}>{Usr.email}</option>
								))}
							</select>
						}
						</div>
						<span className="validationErr">
							{validatioError.user_idError}
						</span>
					</div>
					</div>					
					<div className="col-md-6">
					  <div className="form-group row mb-1">
						<label className="col-form-label col-md-12">
							Current Stage <span className="req-star">*</span>
						</label>
						<div className="col-md-12">
							<select name='stage' className="form-control" id='stageError' onChange={inputHandler} value={leaddata?.stage} >
								<option value="">Select Stage</option>
								{Stages.map(Stage => (
									<option value={Stage.id}>{Stage.title}</option>
								))}
							</select>
						</div>
						<span className="validationErr">
							{validatioError.stageError}
						</span>
					</div>
					</div>
					<div className="col-md-6">
					  <div className="form-group row mb-1">
						<label className="col-form-label col-md-12">
							Created by User
						</label>
						<div className="col-md-12">
							<select name='created_by' className="form-control" id='created_byError' readonly disabled value={leaddata?.created_by} >
								<option value="">Select User</option>
								{AllUsers.map(AllUser => (
									<option value={AllUser.id}>{AllUser.email}</option>
								))}
							</select>
						</div>
						<span className="validationErr">
							{validatioError.stageError}
						</span>
					</div>
					</div>
				<div className="col-md-6">
					  <div className="form-group row mb-1">
						<label className="col-form-label col-md-12">
							DND <span className="req-star">*</span>
						</label>
						<div className="col-md-12">
							<select name='is_dnd' className="form-control" id='is_dndError' onChange={inputHandler} value={leaddata?.is_dnd} >
								<option value="0">No</option>								
								<option value="1">Yes</option>								
							</select>
						</div>						
					</div>
					</div>	
			<div className="col-md-12">
			<br />
			<div className="text-center pull-left">
			{spinloader == '0' ?
			<button className='btn btn-primary' >Submit</button>
			:
			<button disabled className='btn btn-primary' >Updating Lead <i className="fa fa-spinner fa-spin validat"></i></button>
			}
			</div>
			</div>
		</div>                                                   
		</div>
		</form>
	</TabPanel>
	<TabPanel>
	  <div className="col-md-12 mb-50">
		<form onSubmit={addLeadhistory}>
			<div className="row">                                               
				<div className="col-md-12">
					<div className="form-group row mb-1">
						<label className="col-form-label col-md-12">Message <span className="req-star">*</span></label>
						<div className="col-md-12">
							<textarea rows="10" cols="25" className="form-control" type="text" name="message" id='messageError' onChange={inputhistoryHandler}	placeholder="Enter message"/>
				
						</div>
						<span className="validationErr">{validationError.messageError}</span>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group row mb-1">
						<label className="col-form-label col-md-12">Followup Datetime <span className="req-star">*</span></label>
						<div className="col-md-12">
						<Datetime onChange={handleChangefollowupDate} minDate={currentDate} autoComplete="off" name="follow_up_datetime" id="datetimeError" className="form-control" value={leadhistoydata.follow_up_datetime} />
						</div>
						<span className="validationErr">{validationError.datetimeError}</span>
					</div>
				</div>
				<div className="col-md-6">
					  <div className="form-group row mb-1">
						<label className="col-form-label col-md-12">
							Stages <span className="req-star">*</span>
						</label>
						<div className="col-md-12">
							<select name='leadstage' className="form-control" id='leadstageError' onChange={inputhistoryHandler} >
								<option value="">Select Stage</option>
								{Stages.map(Stage => (
									<option value={Stage.id}>{Stage.title}</option>
								))}
							</select>
						</div>
						<span className="validationErr">
							{validationError.leadstageError}
						</span>
					</div>
					</div>
				<div className="col-md-12">
					<div className="form-group row mb-1">
						<label className="col-form-label col-md-12"></label>
						<div className="col-md-12">
						{spinhistoryloader == '0' ?
							<button className='btn btn-primary' >Submit</button>
							:
							<button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
							}
						</div>
						
					</div>
				</div>
				
			</div>
		</form>
		</div>
		 <div className="col-lg-12 col-12">			
			<div className="">
				<h4 className="box-title">Follow up history</h4>
			   </div>
			<div className="row mt-20 customtable">			
				<ReactDatatable
					config={configForTable}
					records={LeadHistory}
					columns={columns}					
				/>
		</div></div>
    </TabPanel>                     
   </Tabs>
		
	</div>
</div>
</div>
</div>
</div>
</section>
{/* /.content */}
</div>
</div>

                <Footer />
            </div>
        </>
    );
};
export default Editleads;
