const config = {
  baseUrl: '/',
  imageUrl: "https://lead-management.ediiie.com/apis/api/uploads/",
  apiUrl: "https://lead-management.ediiie.com/apis/api",
  //~ apiUrl : "http://localhost:3008/api",
  //~ imageUrl: 'http://localhost:3008/api/uploads/',
  superAdmin:1,
  paging_size:50
}
export default config;
