import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestFormData,
} from "../coreFIles/helper";

export const getPrivacyPolicyAction=(data)=>{
  return getRequest('getPrivacyPolicy',data).then(res=>{return res.data})
}
export const updatePrivacyPolicyAction=(data)=>{
  return postRequest('updatePrivacyPolicy',data).then(res=>{return res.data})
}
export const getsettingsListAction = (data) => {
  return getRequest('getsettingsList', data).then(res => { return res.data })
}
export const updatesettingsListsAction = (data) => {
  return postRequest("updatesettingsLists", data).then((res) => {
    return res.data;
  });
};
export const getSocialLinksAction = (data) => {
  return getRequest('getsociallinks', data).then(res => { return res.data })
}
export const updatesociallinksAction = (data) => {
  return postRequest("updatesociallinks", data).then((res) => {
    return res.data;
  });
};
export const gettermsandconditionAction=(data)=>{
  return getRequest('getTermsAndConditions',data).then(res=>{return res.data})
}
export const updatetermsandconditionAction=(data)=>{
  return postRequest('updateTermandCondition',data).then(res=>{return res.data})
}
export const getFaqAction=(data)=>{
  return getRequest('getFaq',data).then(res=>{return res.data})
}
export const getfaqidAction = (data) => {
  return postRequest("getfaqidlist", data).then((res) => {
    return res.data;
  });
};
export const insertfaqAction = (data) => {
  return postRequest("insertfaqlist", data).then((res) => {
    return res.data;
  });
};
export const deletefaqAction = (data) => {
  return postRequest("faqdeletelist", data).then((res) => {
    return res.data;
  });
};
export const updatefaqAction = (data) => {
  return postRequest("updatefaqlist", data).then((res) => {
    return res.data;
  });
};
export const LoginAction = (data) => {
  return postRequest("adminLogin", data).then((res) => {
    return res.data;
  });
};

export const getDashboardStatisticsAction = (data) => {
  return postRequest("getDashboardStatistics", data).then((res) => {
    return res.data;
  });
};

export const getUsersListAction = (data) => {
  return getRequest("getAdminUsersList", data).then((res) => {
    return res.data;
  });
};

export const loginAsUserAction = (data) => {
  return postRequest("loginAsUser", data).then((res) => {
    return res.data;
  });
};

export const changePasswordAction = (data) => {
  return postRequest("changePassword", data).then((res) => {
    return res.data;
  });
};

export const changeUserPasswordAction = (data) => {
  return postRequest("changeUserPassword", data).then((res) => {
    return res.data;
  });
};

export const getAdminProfiles = (data) => {
  return postRequest('getAdminProfiles', data).then(res => { return res.data })
}
export const updateAdminProfilesAction = (data) => {
  return postRequest("updateAdminProfiles", data).then((res) => {
    return res.data;
  });
};

export const UserBlockAction = (data) => {
  return postRequest("usersblockunblock", data).then((res) => {
    return res.data;
  });
};
export const UserBlockUnBlockAction = (data) => {
  return postRequest("usersblockunblock", data).then((res) => {
    return res.data;
  });
};
export const UserDeleteAction = (data) => {
  return postRequest("UserDelete", data).then((res) => {
    return res.data;
  });
};
export const UserUnBlockAction = (data) => {
  return postRequest("userUnblock", data).then((res) => {
    return res.data;
  });
};
export const getAllUsersListAction = (data) => {
  return postRequest("getAllUsersList", data).then((res) => {
    return res.data;
  });
};

// ROLES
export const getRoleslistAction = (data) => {
  return getRequest("getRoleslist", data).then((res) => {
    return res.data;
  });
};


// Priority
export const getAllprioritylistAction = (data) => {
  return getRequest("getAllprioritylist", data).then((res) => {
    return res.data;
  });
};

export const getpriorityidAction = (data) => {
  return postRequest("getpriorityid", data).then((res) => {
    return res.data;
  });
};

export const updatepriorityAction = (data) => {
  return postRequest("updatepriority", data).then((res) => {
    return res.data;
  });
};

// Medium
export const getAllmediumlistAction = (data) => {
  return getRequest("getAllmediumlist", data).then((res) => {
    return res.data;
  });
};

export const getmediumidAction = (data) => {
  return postRequest("getmediumid", data).then((res) => {
    return res.data;
  });
};

export const updatemediumAction = (data) => {
  return postRequest("updatemedium", data).then((res) => {
    return res.data;
  });
};


// BRANDS
export const getAllbrandslistAction = (data) => {
  return getRequest("getAllbrandslist", data).then((res) => {
    return res.data;
  });
};

export const insertbrandAction = (data) => {
  return postRequest("insertbrand", data).then((res) => {
    return res.data;
  });
};

export const getbrandidAction = (data) => {
  return postRequest("getbrandid", data).then((res) => {
    return res.data;
  });
};

export const updatebrandAction = (data) => {
  return postRequest("updatebrand", data).then((res) => {
    return res.data;
  });
};

export const deletebrandAction = (data) => {
  return postRequest("deletebrand", data).then((res) => {
    return res.data;
  });
};

// STAGES

export const getAllstagelistAction = (data) => {
  return getRequest("getAllstagelist", data).then((res) => {
    return res.data;
  });
};

export const insertstageAction = (data) => {
  return postRequest("insertstage", data).then((res) => {
    return res.data;
  });
};

export const getstageidAction = (data) => {
  return postRequest("getstageid", data).then((res) => {
    return res.data;
  });
};

export const updatestageAction = (data) => {
  return postRequest("updatestage", data).then((res) => {
    return res.data;
  });
};

export const deletestageAction = (data) => {
  return postRequest("deletestage", data).then((res) => {
    return res.data;
  });
};

// USERS
export const createUserAction = (data) => {
   return postRequest('createUser', data).then(res => { return res.data })
}
export const updateUserDetailsAction = (data) => {
   return postRequest('updateUserDetails', data).then(res => { return res.data })
}
export const getUserDetailsAction = (data) => {
  return postRequest('getUserDetails', data).then(res => {return res.data})
}


// Leads
export const getAllLeadtypesAction = (data) => {
  return postRequest("getAllLeadtypes", data).then((res) => {
    return res.data;
  });
};

export const getLeadsListAction = (data) => {
  return postRequest("getLeadsList", data).then((res) => {
    return res.data;
  });
};
export const getLeadHistoryAction = (data) => {
  return postRequest("getLeadHistory", data).then((res) => {
    return res.data;
  });
};
export const LeadBlockUnBlockAction = (data) => {
  return postRequest("LeadBlockUnBlock", data).then((res) => {
    return res.data;
  });
};
export const LeadDeleteAction = (data) => {
  return postRequest("deletelead", data).then((res) => {
    return res.data;
  });
};
export const addLeadhistoryAction = (data) => {
   return postRequest('addLeadhistory', data).then(res => { return res.data })
}
export const createLeadAction = (data) => {
   return postRequest('createLead', data).then(res => { return res.data })
}

export const updateLeadAction = (data) => {
   return postRequest('updateLead', data).then(res => { return res.data })
}
export const LeadUploadExcelAction = (data) => {
   return postRequest('UploadLeadsExcel', data).then(res => { return res.data })
}
export const LeadUploadOtherExcelAction = (data) => {
   return postRequest('UploadOtherExcel', data).then(res => { return res.data })
}
export const getLeadDetailsAction = (data) => {
  return postRequest('getLeadDetails', data).then(res => {return res.data})
}



export const getAllBrandsUsersAction = (data) => {
  return getRequest("getAllBrandsUsers", data).then((res) => {
    return res.data;
  });
};

export const getBrandUsersAction = (data) => {
  return postRequest("getBrandUsers", data).then((res) => {
    return res.data;
  });
};

export const getTotalLeadsListAction = (data) => {
  return postRequest("getTotalLeadsList", data).then((res) => {
    return res.data;
  });
};
export const getleadTypeAction = (data) => {
  return postRequest("getleadType", data).then((res) => {
    return res.data;
  });
};







