import React, { useEffect, useState } from 'react'
import Header from '../directives/header';
import Sidebar from '../directives/sidebar';
import { getAdminProfiles, updateAdminProfilesAction } from '../Action/action';
import Cookies from 'js-cookie'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
const loginData = (!Cookies.get('loginSuccessltsAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessltsAdmin'));

const Profile = () => {
    const [usersList, setusersList] = useState('');   
    useEffect(() => {
        if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
        getProfileAPI()
    }, []);


    const getProfileAPI = async () => {
        let res = await getAdminProfiles({
            id: loginData?.id,
            email: loginData?.email,
        });
        if (res.success) {
            setusersList(res.data);
        }
    };

    const inputHandler = (e) => {
        const { name, value } = e.target
        setusersList((old) => {
            return { ...old, [name]: value }
        })
    }
 

    const updateAdminProfiles = async (e) => {
        e.preventDefault()       
        let res = await updateAdminProfilesAction(usersList);
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                window.location.href = `${config.baseUrl}profile`;
            }, 2000);
        } else {
            toast.error(res.msg);
        }
    }

    return (

        <>
            <div className="wrapper">
                <Toaster />
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        {/* Main content */}
                        <div className="content-header">
                            <div className="align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">{usersList.first_name} </h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <section className="content">
                            <div className='box profile' >
                                <div className="row">
                                    <div className='col-lg-2'></div>
                                    <div className="col-lg-8  col-12">
                                        <form className="pt-3 profile">                                          
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input type="text" name="first_name" onChange={inputHandler} value={usersList.first_name} className="form-control input-profile p-1" id="exampleInputEmail1" />
                                            </div>
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <input type="text" onChange={inputHandler} value={usersList.last_name} name="last_name" className="form-control input-profile p-1" id="exampleInputEmail1" />
                                            </div>
                                            <div className="form-group ">
                                                <label>Email</label>
                                                <input type="text" onChange={inputHandler} value={usersList.email} name="email" disabld readOnly className="form-control input-profile p-1" />
                                            </div>                                           
                                            <div className="mt-3">
                                                <a className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn pull-left" onClick={updateAdminProfiles} >Update Profile</a>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='col-lg-2'></div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Profile;
