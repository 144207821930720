/* eslint-disable eqeqeq */
import React, { useState } from "react";
import config from "../coreFIles/config";
import Cookies from "js-cookie";
const loginData = (!Cookies.get('loginSuccessltsAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessltsAdmin'));
const Sidebar = () => {
  const [pageUrl, setPageUrl] = useState(window.location.href);

  const logout = async () => {
    Cookies.remove("loginSuccessltsAdmin");
    window.location.href = config.baseUrl;
  };
  return (
    <>
      <aside className="main-sidebar">
        {/* sidebar*/}
        <section className="sidebar position-relative">
          <div className="multinav">
            <div className="multinav-scroll" style={{ height: "100%" }}>
              {/* sidebar menu*/}
              <ul className="sidebar-menu" data-widget="tree">
                <li className={pageUrl.match("/dashboard") ? "active" : ""}>
                  <a href={`${config.baseUrl}dashboard`}>
                    <i className="fa fa-dashboard" />
                    <span>Dashboard </span>
                  </a>
                </li>  
          {loginData?.role === config.superAdmin ? (
		  <>
			<li className={pageUrl.match("priority") ? "active" : ""}>
			  <a href={`${config.baseUrl}priority`}>
				<i className="fa fa-align-center" />
				
				<span>Priority</span>
			  </a>
			</li>
			<li className={pageUrl.match("medium") ? "active" : ""}>
			  <a href={`${config.baseUrl}medium`}>
				<i className="fa fa-medium" />
				
				<span>Medium</span>
			  </a>
			</li>
			<li className={pageUrl.match("brand") ? "active" : ""}>
			  <a href={`${config.baseUrl}brands`}>
				<i className="fa fa-flag-o" />
				
				<span>Brands</span>
			  </a>
			</li>
			<li className={pageUrl.match("stage") ? "active" : ""}>
			  <a href={`${config.baseUrl}stages`}>
				<i className="fa fa-stack-overflow" />
				
				<span>Lead Stages</span>
			  </a>
			</li>
			<li className={pageUrl.match("user") ? "active" : ""}>
			  <a href={`${config.baseUrl}users`}>
				<i className="fa fa-users" />
				
				<span>Users</span>
			  </a>
			</li>
		  </>
		) : null} 
                             
                   
                
                <li className={pageUrl.match("leads") ? "active" : ""}>
                  <a href={`${config.baseUrl}leads`}>
                    <i className="fa fa-tasks" />
                    
                    <span>Leads</span>
                  </a>
                </li>     
               
                <li className={pageUrl.match("/changepassword") ? "active" : ""}>
                  <a href={`${config.baseUrl}changepassword`}>
                    <i className="fa fa-lock" />
                   
                    <span>Change Password</span>
                  </a>
                </li>
                
                <li className="">
                  <a href="javascript:;" onClick={logout}>
                     <i className="fa fa-sign-out" />
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </aside>
    </>
  );
};
export default Sidebar;
