import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { getAllbrandslistAction,createLeadAction,getAllBrandsUsersAction,getAllstagelistAction, getAllLeadtypesAction, getAllprioritylistAction, getAllmediumlistAction } from "../Action/action";
const loginData = (!Cookies.get('loginSuccessltsAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessltsAdmin'));


const Addleads = () => {
	const [Brands, setBrands] = useState([]);
	const [Stages, setStages] = useState([]);
	const [leadTypes, setleadTypes] = useState([]);
	const [AllBrandsUsers, setAllBrandsUsers] = useState([]);
	const [filteredUsers, setfilteredUsers] = useState([]);
	const [spinloader, setspinloader] = useState(0);  
	const [validatioError, setvalidatioError] = useState({});
	const [mediumlist, setmediumList] 				= useState([]); 
    const [prioritylist, setpriorityList] 			= useState([]);	
	const [leaddata, setleaddata] = useState({
        first_name: '',
        last_name: '',
        lead_type: 4,
        phone: '',
        priority_id: '',
        medium_id: '',
        email: '',
        personal_email: '',
        linkedin_url: '',
        designation: '',
        organisation: '',
        geography: '',
        status: '0',       
        stage: '',       
        brand_id: '',       
        user_id: '',       
        sales_navigator_url: '',       
        corporate_phone: '',       
        website: '',       
    });
   
  
   useEffect(() => {
	    if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
        getpriorityAPI();
        getmediumAPI();
        getBrandsAPI()    
        getLeadTypesAPI()    
        getStagesAPI()    
        getAllBrandsUsersAPI()    
      
    }, [])
        
  const getBrandsAPI = async () => {
        let res = await getAllbrandslistAction();
        if (res.success) {
			 setBrands(res.data)
            
        }
    }  
  
  const getpriorityAPI = async () => {
		let res = await getAllprioritylistAction();
		if (res.success) {
		  setpriorityList(res.data);
		}
	  };
	const getmediumAPI = async () => {
		let res = await getAllmediumlistAction();
		if (res.success) {		 
		  setmediumList(res.data);
		}
	  };
      
    
  const getLeadTypesAPI = async () => {
        let res = await getAllLeadtypesAction();
        if (res.success) {
           setleadTypes(res.data)
        }
    }  

  const getStagesAPI = async () => {
        let res = await getAllstagelistAction();
        if (res.success) {
            setStages(res.data)
        }
    }  

  const getAllBrandsUsersAPI = async () => {
        let res = await getAllBrandsUsersAction();
        if (res.success) {
            setAllBrandsUsers(res.data)            
        }
    }  

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setleaddata((old) => {
      return { ...old, [name]: value };
    });
  };
  
  const inputBrandHandler = e => {
        const { name, value, id } = e.target        
        setleaddata({ ...leaddata, [name]: value })
        if (value !== '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }          
      const result = AllBrandsUsers.filter(user => user.brand_id == value);     
      setfilteredUsers(result);  
    }
  
  function validate() {	 
	let first_nameError 	= "";
	let last_nameError 		= "";    
    let emailError 			= "";
    let phoneError 			= "";    
    let stageError 			= "";
    let brand_idError 		= "";
    let user_idError 		= "";
    let lead_typeError 		= "";
    let priority_idError 	= "";
    let medium_idError 		= "";
       
    if (leaddata.first_name === "") {
      first_nameError = "First name is required.";
    }  
    if (leaddata.last_name === "") {
      last_nameError = "Last name is required.";
    }     
    if (leaddata.email === "") {
      emailError = "Email is required.";
    }      
      
    if (leaddata.stage === "") {
      stageError = "Stage is required.";
    } 
    if (leaddata.brand_id === "") {
      brand_idError = "Brand is required.";
    } 
    if (leaddata.user_id === "") {
      user_idError = "User is required.";
    }
    if (leaddata.lead_type === "") {
      lead_typeError = "Lead type is required.";
    }
    if (leaddata.priority_id === "") {
      priority_idError = "Priority type is required.";
    }
    if (leaddata.medium_id === "") {
      medium_idError = "Medium is required.";
    }
    
    if (first_nameError || last_nameError || emailError || stageError || brand_idError || user_idError || lead_typeError || priority_idError|| medium_idError) {
      setvalidatioError({
        first_nameError,last_nameError, emailError,stageError,brand_idError,user_idError,lead_typeError,priority_idError, medium_idError
      });
      return false;
    } else {
      return true;
    }
  }
  
  const createLead = async (e) => {
        e.preventDefault()
        const isValid = validate();
        console.log(isValid);
        if (!isValid) {		
        }
        else {
            setspinloader(1);           
			let res = await createLeadAction(leaddata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}leads`;
				}, 2000);
			}			 
			else {
				toast.error(res.msg);
			}
		} 
	}
  
  return (
        <>
            <div className="wrapper">
                <Header />
                <Toaster />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Create Lead</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        {/* Content Header (Page header) */}

                        {/* Main content */}
<section className="content">
	<div className="row">
		<div className="col-lg-12 col-12">
			<div className="box">
				<div className="box-header with-border">
					<h4 className="box-title">Create Lead</h4>
					<a href={`${config.baseUrl}leads`} className="btn btn-sm btn-primary add_btn">Back</a> 
				</div>
<div className="row mt-20 mb-50 ml-15 mr-15">
<form onSubmit={createLead}>
	<div className="col-md-12">
		<div className="row">                                               
			<div className="col-md-6">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">First Name <span className="req-star">*</span></label>
					<div className="col-md-12">
						<input className="form-control" type="text" name="first_name" id='first_nameError' onChange={inputHandler} placeholder="Enter first name"/>
					</div>
					<span className="validationErr">{validatioError.first_nameError}</span>
				</div>
			</div>
			<div className="col-md-6">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Last Name <span className="req-star">*</span></label>
					<div className="col-md-12">
						<input className="form-control" type="text" name="last_name" id='last_nameError' onChange={inputHandler} placeholder="Enter last name"/>
					</div>
					<span className="validationErr">{validatioError.last_nameError}</span>
				</div>
			</div>			
			<div className="col-md-6">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Email <span className="req-star">*</span></label>
					<div className="col-md-12">
						<input className="form-control" type="email" name="email" id='emailError' onChange={inputHandler} placeholder="Enter email address"/>
					</div>
					<span className="validationErr">{validatioError.emailError}</span>
				</div>
			</div>
			<div className="col-md-6">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Personal Email</label>
					<div className="col-md-12">
						<input className="form-control" type="email" name="personal_email" id='personal_emailError' onChange={inputHandler} placeholder="Enter personal email address" />
					</div>							
				</div>
			</div>
			<div className="col-md-6">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Phone</label>
					<div className="col-md-12">
						<input className="form-control" type="text" name="phone" id='phoneError' onChange={inputHandler} placeholder="Enter phone"/>
					</div>
					
				</div>
			</div>
			<div className="col-md-6">
				<div className="form-group row mb-1">
					<label className="col-form-label col-md-12">Corporate phone</label>
					<div className="col-md-12">
						<input className="form-control" type="text" name="corporate_phone" id='corporate_phoneError' onChange={inputHandler} placeholder="Enter corporate phone"/>
					</div>					
				</div>
			</div>
			<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Linkedin URL</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="linkedin_url" id='linkedin_urlError' onChange={inputHandler} placeholder="Enter linkedin url" />
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Designation</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="designation" id='designationError' onChange={inputHandler} placeholder="Enter designation" />
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Organisation</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="organisation" id='organisationError' onChange={inputHandler} placeholder="Enter organisation" />
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Geography</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="geography" id='geographyError' onChange={inputHandler} placeholder="Enter geography" />
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Sales navigator url</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="sales_navigator_url" id='sales_navigator_urlError' onChange={inputHandler} placeholder="Enter sales navigator url" />
							</div>							
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group row mb-1">
							<label className="col-form-label col-md-12">Website</label>
							<div className="col-md-12">
								<input className="form-control" type="text" name="website" id='websiteError' onChange={inputHandler} placeholder="Enter website" />
							</div>							
						</div>
					</div>
			
			
			<div className="col-md-6">
			  <div className="form-group row mb-1">
				<label className="col-form-label col-md-12">
					Priority Type <span className="req-star">*</span>
				</label>
				<div className="col-md-12">
					<select name='priority_id' className="form-control" id='priority_idError' onChange={inputHandler}>
						<option value="">Select priority type</option>
						{prioritylist.map(priority => (
							<option value={priority.id}>{priority.title}</option>
						))}
					</select>
				</div>
				<span className="validationErr">
					{validatioError.priority_idError}
				</span>
			</div>
			</div>	
			
			<div className="col-md-6">
			  <div className="form-group row mb-1">
				<label className="col-form-label col-md-12">
					Medium <span className="req-star">*</span>
				</label>
				<div className="col-md-12">
					<select name='medium_id' className="form-control" id='medium_idError' onChange={inputHandler}>
						<option value="">Select medium </option>
						{mediumlist.map(medium => (
							<option value={medium.id}>{medium.title}</option>
						))}
					</select>
				</div>
				<span className="validationErr">
					{validatioError.medium_idError}
				</span>
			</div>
			</div>	
			
			
			
				
			
			<div className="col-md-6">
			  <div className="form-group row mb-1">
				<label className="col-form-label col-md-12">
					Brand <span className="req-star">*</span>
				</label>
				<div className="col-md-12">
					<select name='brand_id' className="form-control" id='brand_idError' onChange={inputBrandHandler}>
						<option value="">Select Brand</option>
						{Brands.map(Brand => (
							<option value={Brand.brand_id}>{Brand.brand_name}</option>
						))}
					</select>
				</div>
				<span className="validationErr">
					{validatioError.brand_idError}
				</span>
			</div>
			</div>			
			<div className="col-md-6">
			  <div className="form-group row mb-1">
				<label className="col-form-label col-md-12">
					User <span className="req-star">*</span>
				</label>
				<div className="col-md-12">
					<select name='user_id' className="form-control" id='user_idError' onChange={inputHandler}>
						<option value="">Select User</option>
						{filteredUsers.map(Usr => (
							<option value={Usr.id}>{Usr.email}</option>
						))}
					</select>
				</div>
				<span className="validationErr">
					{validatioError.user_idError}
				</span>
			</div>
			</div>
			<div className="col-md-6">
			  <div className="form-group row mb-1">
				<label className="col-form-label col-md-12">
				Current Stage <span className="req-star">*</span>
				</label>
				<div className="col-md-12">
					<select name='stage' className="form-control" id='stageError' onChange={inputHandler}>
						<option value="">Select Stage</option>
						{Stages.map(Stage => (
							<option value={Stage.id}>{Stage.title}</option>
						))}
					</select>
				</div>
				<span className="validationErr">
					{validatioError.stageError}
				</span>
			</div>
			</div>



<div className="col-md-12">
<br />
<div className="text-center pull-left">
{spinloader == '0' ?
<button className='btn btn-primary' >Submit</button>
:
<button disabled className='btn btn-primary' >Creating Lead <i className="fa fa-spinner fa-spin validat"></i></button>
}
</div>
</div>
</div>                                                   
</div>
</form>
</div>
</div>
</div>
</div>
</section>
{/* /.content */}
</div>
</div>

                <Footer />
            </div>
        </>
    );
};
export default Addleads;
