import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { updatemediumAction, getmediumidAction } from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import avt from '../assets/avata_profile.jpg'
import { Link, useParams } from 'react-router-dom';

const Editmedium = () => {
	const { id } = useParams();	
 const [form, setForm] = useState({id: id, title: "", display_order: ""});
  const [validatioError, setvalidatioError] = useState({});
 
  useEffect(() => {
    getmediumidAPI();
  }, []);

  const getmediumidAPI = async () => {
    let res = await getmediumidAction({ id: id  });
    if (res.success) {
     let data = res.data;
      setForm((old) => {
        return { ...old, id: id, title: data.title, display_order: data.display_order};
      });
    }
  };
  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };
function validate() {
    let titleError = "";   
    let display_orderError = "";   
    
    if (form.title === "") {
      titleError = "Title is required.";
    }
    if (form.display_order === "") {
      display_orderError = "Title is required.";
    }
   
   
    if (titleError || display_orderError) {
      setvalidatioError({
        titleError,display_orderError
      });
      return false;
    } else {
		 setvalidatioError({
        titleError,display_orderError
      });
      return true;
    }
  }
 
  const updatemedium = async (e) => {
    e.preventDefault(); 
    const isValid = validate();	
     if (!isValid) {
    } else {
		let res = await updatemediumAction(form);
		if (res.success) {
		  toast.success(res.msg);
		  setTimeout(() => {
			window.location.href = `${config.baseUrl}medium`;
		  }, 1200);
		} else {
		  toast.error(res.msg);
		}
	}	
  };

  return (
    <>
      <div className="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-5 pb-2">Edit Medium</h3>
                </div>
              </div>
              <hr />
            </div>
            {/* Content Header (Page header) */}
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Edit Medium</h4>
                       <a href={`${config.baseUrl}medium`} className="btn btn-sm btn-primary add_btn">Back</a> 
                    </div>
                    <div className="row mt-20 mb-50">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">
                             Title <span className="req-star">*</span>
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                name="title"
                                value={form.title}
                                onChange={inputHandler}
                                placeholder=""
                              />
                            </div>
                            <span className="validationErr">
                              {validatioError.titleError}
                            </span>
                          </div> 
                            <br />                      
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">
                             Display Order <span className="req-star">*</span>
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                name="display_order"
                                value={form.display_order}
                                onChange={inputHandler}
                                placeholder=""
                              />
                            </div>
                            <span className="validationErr">
                              {validatioError.display_orderError}
                            </span>
                          </div>                        
                                                                         

                          <br />
                          <br />
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={updatemedium}
                            >
                              Save Change
                            </button>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Editmedium;
